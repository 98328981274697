import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import ScoreTile from '../Report/ScoreTile';
import ScoreExplained from '../Report/ScoreExplained';
import ScoreStatistics from '../Report/ScoreStatistics';
import Error from '../Shared/Error';
import { LoadingModal } from '../Profile/Shared';
import { SwappingRule } from '../Shared/Layout';
import api from '../../services/api';

export default function ScoreSummary({ scoreId, navigate, location }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(location.state);

  const load = useCallback(() => {
    setLoading(true);
    setError(false);
    api.getScore(scoreId)
      .then(r => {
        setLoading(false);
        setData(r.data);
      })
      .catch(e => {
        if (e.status === 404)
          navigate('/not-found');
        else
          setError(true);
        setLoading(false);
      });
  }, [scoreId, navigate]);

  useEffect(() => {
    if ((!data || Object.keys(data).length === 0) && !!scoreId) {
      load();
    }
  }, [data, scoreId, load]);

  return <>
    {!loading && data && <>
      <Row>
        <Col>

          <Row className="align-items-center">
            <Col className="p-3">
              <h4 className="text-uppercase text-center mb-0">Your RISE Score</h4>
            </Col>
          </Row>

          <Row>
            <Col sm={6} className="p-3 d-flex align-items-center">
              <ScoreTile score={data.score} created={data.created} />
            </Col>

            <SwappingRule breakpoint="sm" />

            <Col sm={6} className="p-3">
              <ScoreExplained score={data.score} noTips={true} />

              <Button onClick={() => { navigate('/', { state: data.inputs }) }}>Update Your Answers</Button>
            </Col>
          </Row>

          <div className="px-3">
            <hr className="my-0" />
          </div>

          <Row>
            <Col sm={12}>
              <ScoreStatistics data={data} />
            </Col>
          </Row>
            
        </Col>
      </Row>
    </>}

    <LoadingModal loading={loading} message={'Loading your RISE Score'} />

    {error && <Error onClick={load} />}
  </>;
}
