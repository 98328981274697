import axios from 'axios';
import auth from './auth';
import config from '../config';

function buildProfiles(profiles) {
  return {
    primary: buildProfile(profiles.primary),
    secondary: profiles.hasSecondary && profiles.secondary ? buildProfile(profiles.secondary) : null
  }
}

function buildProfile(profile) {
  return {
    name: profile.name || null,
    yearOfBirth: parseInt(profile.yearOfBirth),
    retirementAge: parseInt(profile.retirementAge),
    gender: profile.gender || null,
    health: profile.health || null,
    state: profile.state || null
  };
}

function buildSavings(savings) {
  return {
    savings: (savings && parseFloat(savings.savings)) || null,
    primaryContributions: (savings && parseFloat(savings.primaryContributions)) || null,
    secondaryContributions: (savings && parseFloat(savings.secondaryContributions)) || null
  };
}

function buildAllocations(allocations) {
  return {
    bonds: allocations.bonds ? parseFloat(allocations.bonds) : null,
    largeCap: allocations.largeCap ? parseFloat(allocations.largeCap) : null,
    midCap: allocations.midCap ? parseFloat(allocations.midCap) : null,
    intl: allocations.intl ? parseFloat(allocations.intl) : null,
    emerging: allocations.emerging ? parseFloat(allocations.emerging) : null
  };
}

function buildIncome(income) {
  return {
    socialSecurity: income.hasSocialSecurity && income.socialSecurity ? buildIncomeGroup(income.socialSecurity, false) : null,
    pension: income.hasPension && income.pension ? buildIncomeGroup(income.pension, false) : null,
    annuity: income.hasAnnuity && income.annuity ? buildIncomeGroup(income.annuity, true) : null,
    otherIncome: income.hasOtherIncome && income.otherIncome ? buildIncomeGroup(income.otherIncome, true) : null
  };
}

function buildIncomeGroup(incomeGroup, hasInflation) {
  return {
    primary: incomeGroup.primary ? buildIncomeItem(incomeGroup.primary, hasInflation) : null,
    secondary: incomeGroup.secondary ? buildIncomeItem(incomeGroup.secondary, hasInflation) : null
  }
}

function buildIncomeItem(incomeItem, hasInflation) {
  var item = {
    amount: parseFloat(incomeItem.amount),
    commencementAge: parseInt(incomeItem.commencementAge)
  };

  if (hasInflation)
    item.inflation = incomeItem.inflation ? true : false;

  return item;
}

function buildExpenses(expenses) {
  return {
    jointBasicLivingExpenses: expenses.jointBasicLivingExpenses ? parseFloat(expenses.jointBasicLivingExpenses) : null,
    primaryBasicLivingExpenses: expenses.primaryBasicLivingExpenses ? parseFloat(expenses.primaryBasicLivingExpenses) : null,
    secondaryBasicLivingExpenses: expenses.secondaryBasicLivingExpenses ? parseFloat(expenses.secondaryBasicLivingExpenses) : null,
    primaryMedicalExpenses: expenses.primaryMedicalExpenses ? parseFloat(expenses.primaryMedicalExpenses) : null,
    secondaryMedicalExpenses: expenses.secondaryMedicalExpenses ? parseFloat(expenses.secondaryMedicalExpenses) : null
  };
}

function buildScore(fields) {
  var request = {};
  
  request.profiles = buildProfiles(fields.profiles || {});
  request.savings = buildSavings(fields.savings);
  request.allocations = buildAllocations(fields.allocations);
  request.income = buildIncome(fields.income || {});
  request.expenses = buildExpenses(fields.expenses);

  return request;
}

function decorateProfile(profile) {
  if (profile) {
    if (profile.profiles && profile.profiles.secondary)
      profile.profiles.hasSecondary = true;
    
    if (profile.income) {
      if (profile.income.socialSecurity)
        profile.income.hasSocialSecurity = true;
      if (profile.income.pension)
        profile.income.hasPension = true;
      if (profile.income.annuity)
        profile.income.hasAnnuity = true;
      if (profile.income.otherIncome)
        profile.income.hasOtherIncome = true;
    }
  }
}

function handleError(e, isProtected = true) {
  if (axios.isCancel(e)) {
    const obj = new Error();
    obj.cancelled = true;
    obj.status = null;
    obj.errors = {};
    throw obj;
  }

  if (e && e.response) {
    if (isProtected && e.response.status === 401) {
      auth.logout();
      window.location.href = '/login';
    }
    else {
      const obj = new Error();
      obj.cancelled = false;
      obj.status = e.response.status;
      obj.errors = {};

      if (e.response.status === 400) {
        if (e.response.data && e.response.data.errors && Object.keys(e.response.data.errors).length > 0)
          obj.errors = e.response.data.errors;
        else
          obj.errors = { '': 'An unexpected error has occured.' };
      }

      throw obj;
    }
  }
  else
    throw e;
}

function publicGet(url, cancelToken) {
  return axios.get(`${config.API_URL}${url}`, { cancelToken })
    .catch(e => handleError(e, false));
}

function publicPost(url, data, cancelToken) {
  return axios.post(`${config.API_URL}${url}`, data, { cancelToken })
    .catch(e => handleError(e, false));
}

function protectedGet(url, cancelToken, ignoreProtection = false) {
  let promise = auth.getToken();

  if (!ignoreProtection)
    promise = promise.catch(() => {
      const error = new Error();
      error.response = { status: 401 };
      throw error;
    });
  
  return promise
    .then(t => axios.get(`${config.API_URL}${url}`, { cancelToken, headers: { 'Authorization': `Bearer ${t.access_token}` } }))
    .catch(e => handleError(e, !ignoreProtection));
}

function protectedPost(url, data, cancelToken) {
  return auth.getToken()
    .catch(() => {
      const error = new Error();
      error.response = { status: 401 };
      throw error;
    })
    .then(t => axios.post(`${config.API_URL}${url}`, data, { cancelToken, headers: { 'Authorization': `Bearer ${t.access_token}` } }))
    .catch(e => handleError(e));
}

function protectedDelete(url, cancelToken) {
  return auth.getToken()
    .catch(() => {
      const error = new Error();
      error.response = { status: 401 };
      throw error;
    })
    .then(t => axios.delete(`${config.API_URL}${url}`, { cancelToken, headers: { 'Authorization': `Bearer ${t.access_token}` } }))
    .catch(e => handleError(e));
}

export default {
  getCancelTokenSource() {
    return axios.CancelToken.source();
  },

  calculateScore(fields) {
    return publicPost('/scores', buildScore(fields));
  },

  calculateSocialSecurityBenefit(fields) {
    return publicPost('/socialsecurity', fields);
  },

  getReturns(allocations) {
    return publicPost('/returns', buildAllocations(allocations));
  },

  getScore(id) {
    return publicGet(`/scores/${id}`);
  },

  registerUser(fields) {
    return publicPost('/accounts', fields);
  },
  
  forgotPassword(fields) {
    return publicPost('/accounts/forgot-password', fields);
  },
  
  resetPassword(fields) {
    return publicPost('/accounts/reset-password', fields);
  },

  getAccount() {
    return protectedGet('/accounts');
  },
  
  updateEmail(fields) {
    return protectedPost('/accounts/email', fields);
  },

  changePassword(fields) {
    return protectedPost('/accounts/password', fields);
  },

  changeNotifications(fields) {
    return protectedPost('/accounts/notifications', fields);
  },

  closeAccount() {
    return protectedDelete('/accounts');
  },

  getProfile(ignoreProtection = false) {
    return protectedGet('/profile', undefined, ignoreProtection)
      .then(r => {
        decorateProfile(r.data);
        return r;
      });
  },

  saveProfileProfiles(profiles) {
    return protectedPost('/profile/profiles', buildProfiles(profiles))
      .then(r => {
        decorateProfile(r.data);
        return r;
      });
  },

  saveProfileSavings(savings) {
    return protectedPost('/profile/savings', buildSavings(savings))
      .then(r => {
        decorateProfile(r.data);
        return r;
      });
  },

  saveProfileAllocations(allocations) {
    return protectedPost('/profile/allocations', buildAllocations(allocations))
      .then(r => {
        decorateProfile(r.data);
        return r;
      });
  },

  saveProfileIncome(income) {
    return protectedPost('/profile/income', buildIncome(income))
      .then(r => {
        decorateProfile(r.data);
        return r;
      });
  },

  saveProfileExpenses(expenses) {
    return protectedPost('/profile/expenses', buildExpenses(expenses))
      .then(r => {
        decorateProfile(r.data);
        return r;
      });
  },

  getReports() {
    return protectedGet('/reports');
  },

  getReportLatest() {
    return protectedGet('/reports/latest');
  },

  getReport(id) {
    return protectedGet(`/reports/${id}`);
  },

  simulatorAge(primaryRetirementAge, secondaryRetirementAge, cancelToken) {
    return protectedPost('/simulator/age', { primaryRetirementAge, secondaryRetirementAge }, cancelToken);
  },

  simulatorContributions(primaryContributions, secondaryContributions, cancelToken) {
    return protectedPost('/simulator/contributions', { primaryContributions, secondaryContributions }, cancelToken);
  },

  simulatorAllocations(allocations, cancelToken) {
    return protectedPost('/simulator/allocations', allocations, cancelToken);
  },

  simulatorIncome(primaryIncome, secondaryIncome, cancelToken) {
    return protectedPost('/simulator/income', { primaryIncome, secondaryIncome }, cancelToken);
  },

  simulatorExpenses(jointBasicLivingExpenses, primaryBasicLivingExpenses, secondaryBasicLivingExpenses, primaryMedicalExpenses, secondaryMedicalExpenses, cancelToken) {
    return protectedPost('/simulator/expenses', { jointBasicLivingExpenses, primaryBasicLivingExpenses, secondaryBasicLivingExpenses, primaryMedicalExpenses, secondaryMedicalExpenses }, cancelToken);
  },

  simulatorAnnuities(allocation, cancelToken) {
    return protectedPost('/simulator/annuities', { allocation }, cancelToken);
  }
}
