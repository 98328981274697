import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import ScoreTiles from './ScoreTiles';
import api from '../../services/api';
import { formatValue } from '../../helpers';

const AnnuitiesSlider = ({ value, disabled, onChange }) => {
  return <Row className="justify-content-center">
    <Col xs={12} className="text-center py-4">
      <Row>
        <Col>
          <label htmlFor="customRange" className="text-center">Allocating <strong>{formatValue(value)}%</strong> of your savings to an annuity</label>
          <input type="range" className="custom-range" id="customRange" min={0} max={50}
            disabled={disabled}
            value={value}
            onChange={e => onChange(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="text-left">
          <strong>0%</strong>
        </Col>
        <Col xs={6} className="text-right">
          <strong>50%</strong>
        </Col>
      </Row>
    </Col>
  </Row>;
}

const Annuities = ({ profile }) => {
  const [annuities, setAnnuities] = useState(0);
  const timeout = useRef(null);
  const cancelTokenSource = useRef(null);
  const [loading, setLoading] = useState(true);
  const [locked, setLocked] = useState(true);
  const [scores, setScores] = useState(null);

  useEffect(() => {
    setLoading(true);

    if (timeout.current) clearTimeout(timeout.current);
    if (cancelTokenSource.current) cancelTokenSource.current.cancel('Cancelled');
    
    timeout.current = setTimeout(() => {
      setLocked(true);
      
      cancelTokenSource.current = api.getCancelTokenSource();
      api.simulatorAnnuities(parseInt(annuities) >= 0 ? parseInt(annuities) : null, cancelTokenSource.current.token)
        .then(r => {
          setScores(r.data);
          setLoading(false);
          setLocked(false);
        })
        .catch(e => {
          if (!e.cancelled) {
            setLoading(false);
            setLocked(false);
          }
        });
    }, 1000);

    return function cleanup() {
      if (timeout.current) clearTimeout(timeout.current);
      if (cancelTokenSource.current) cancelTokenSource.current.cancel('Cancelled');
    }
  }, [annuities]);

  return <Row>
    <ScoreTiles scores={scores} loading={loading} />

    <Col xs={12} className="p-3">
      <h5 className="text-uppercase mb-0">Allocate to an Annuity</h5>

      <AnnuitiesSlider value={annuities} onChange={v => setAnnuities(v)} disabled={locked} />

      <Row>
        <Col>
          <p>
            Annuities can be an important part of a diversified retirement portfolio because they can ensure that your retirement income is protected even when there are downturns in the market.
            So no matter how your other retirement investments perform, annuities can provide you with a source of protected lifetime income that few financial products can offer.
          </p>
          <p>
            There are annuities to fit a variety of individual investor needs with different options and benefits.
            This allows you to tailor them to your specific needs — such as protecting your income from market downturns, providing lifetime income payments or leaving money to your family.
          </p>
          <p>
            The RISE Score Simulator uses a variety of variable, deferred income and fixed income annuities when coming up with your simulated RISE Score.
          </p>
        </Col>
      </Row>
    </Col>
  </Row>;
};

export default connect(state => ({
  profile: state.profile
}))(Annuities);